import { useState } from 'react'
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// types
import { IOrgCredentialRow } from 'src/types/orgCredential';
// components
import { useDashboardContext } from 'src/context/hooks';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import useFetcher, { endpoints } from 'src/utils/axios';
import { ConfirmDialog } from 'src/components/custom-dialog';
import MTRetrieveData from 'src/components/customRetrieve/mt-retrieve';
import UserQuickEditForm from './credentials-quick-edit-form';

// ----------------------------------------------------------------------

type Props = {
  row: IOrgCredentialRow;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: (id: string) => void;
  onUpdateRow: VoidFunction;
  onUpdateEnv: VoidFunction;
  wizzardSetupType?: string;
  setWizzardUpdate?: (status: string) => void;
};

export default function TableRowMetadata({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  onUpdateRow,
  onUpdateEnv,
  wizzardSetupType,
  setWizzardUpdate,
}: Props) {
  const {
    status,
    org_crediantial_name,
    instance_url,
    api_name,
    env_api_name,
    env_name,
    createdBy,
  } = row;

  const quickEdit = useBoolean();
  const confirm = useBoolean();
  const openCustomRetrieve = useBoolean();
  const { updateData, fetchData } = useFetcher();
  const [ newWizzardStatus, setNewWizzardStatus ] = useState(wizzardSetupType)
  const popover = usePopover();
  const theme = useTheme();
  const handleDelete = () => {
    onDeleteRow(row.credentials_id);
    confirm.onFalse();
  };
  const deactivateUserCredential = () => {
    const requestBody = {
      ...row,
      status: 'Inactive',
    };
    updateData(endpoints.credential.update, [requestBody]).then(() => {
      onUpdateRow();
    });
  };
  const handleWizzardUpdate = (newWizzardStatusUpdate: string) => {
    setNewWizzardStatus(newWizzardStatusUpdate);
    if (setWizzardUpdate) {
      setWizzardUpdate(newWizzardStatusUpdate);
    }
  }

  const activateUserCredential = () => {
    const requestBody = {
      ...row,
      status: 'Connected',
    };
    updateData(endpoints.credential.update, [requestBody]).then(() => {
      onUpdateRow();
    });
  };
  const retrieveLanguages = () => {
    fetchData([endpoints.credential.retrieveLanguages, { params: { cred_id: row.credentials_id } }]).then(() => {
      onUpdateEnv();
    })
  }
  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell>
        <Box>{org_crediantial_name}</Box>
      </TableCell>
      <TableCell>
        <Box>{api_name}</Box>
      </TableCell>
      <TableCell>
        <Box>{createdBy}</Box>
      </TableCell>
      <TableCell>
        <Box>{env_name}</Box>
      </TableCell>
      <TableCell>
        <Box>{env_api_name}</Box>
      </TableCell>
      <TableCell>
        <Box>{instance_url}</Box>
      </TableCell>

      <TableCell sx={{ textAlign: 'center' }}>
        <Label
          variant="soft"
          color={
            (status === 'Connected' && 'success') ||
            (status === 'Disconnected' && 'warning') ||
            (status === 'Inactive' && 'error') ||
            'default'
          }
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Tooltip title="Edit Credential" placement="top" arrow>
          <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
        <Tooltip 
          title={
            <div style={{ padding: 5, maxWidth: '20em'}}>
              <p style={{ lineHeight: '1.5', margin: 0, fontSize: '1.0rem'}}>
                Click this action to retrieve metadata.
              </p>
              <ul style={{ lineHeight: '1.8', margin: 0, paddingLeft: '1.2em', fontSize: '0.8rem', paddingTop: '0.6em'}}>
                <li>Select &quot;Retrieve Metadata&quot;</li>
              </ul>
            </div>
          } 
          open={((newWizzardStatus === 'retrieveTranslation' && !openCustomRetrieve.value) || false)} 
          arrow 
          placement="top"
        >
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  const renderPopover = (
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        arrow='left-top'
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            retrieveLanguages();
          }}
        >
          <Iconify icon="mdi:refresh" width={16} />
          Retrieve Languages
        </MenuItem>
        <MenuItem
          onClick={() => {
            popover.onClose();
            openCustomRetrieve.onTrue()
          }}
        >
          <Iconify icon="mdi:refresh" width={16} />
          Retrieve Metadata
        </MenuItem>
        { (row.status === 'Connected') ?
          <MenuItem
            onClick={() => {
              popover.onClose();
              deactivateUserCredential()
            }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" width={16} />
            Deactivate Credential
          </MenuItem> 
          :
          <MenuItem
            onClick={() => {
              popover.onClose();
              activateUserCredential()
            }}
          >
            <Iconify icon="solar:home-add-bold" width={16} />
            Activate Credential
          </MenuItem>
        }
      </CustomPopover>
  )

  return (
    <>
      <MTRetrieveData
        open={openCustomRetrieve.value}
        onClose={openCustomRetrieve.onFalse}
        cred_id={row.credentials_id}
        wizzardSetupType={newWizzardStatus}
        setWizzardUpdate={handleWizzardUpdate}
      />
      {renderPrimary}
      {renderPopover}
      <UserQuickEditForm credential={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        // title="Delete"
        title={row.api_name}
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        }
      />
    </>
  );
}