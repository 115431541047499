import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------
export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const Maintenance = lazy(() => import('src/pages/maintenance'));
const TutorialsListPage = lazy(() => import('src/pages/tutorials/list'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [{ path: 'tutorials', element: <TutorialsListPage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      { path: 'maintenance', element: <Maintenance /> },
    ],
  },
];
