import { useCallback, useEffect, useState } from 'react';
// hooks
import { useDashboardContext } from 'src/context/hooks';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import usePermission from 'src/permission/has-permission';
import { MANAGE_SETUP } from 'src/permission/all_permissions';
//
import SetupWizzardIcon from 'src/components/setup-wizzard/wizzard-icon';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();
  const { currentOrg } = useDashboardContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const wizzardSetupStatus = currentOrg?.setupWizzardStatus || 'initial';

  const { allowedPermission, isPermissionLoading } = usePermission(MANAGE_SETUP.MANAGE_SETUP);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
        {allowedPermission && !(wizzardSetupStatus === 'completed') && <SetupWizzardIcon wizzardStatus={wizzardSetupStatus}/>}
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
          {allowedPermission && !(wizzardSetupStatus === 'completed') && <SetupWizzardIcon wizzardStatus={wizzardSetupStatus}/>}
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
        {allowedPermission && !(wizzardSetupStatus === 'completed') && <SetupWizzardIcon wizzardStatus={wizzardSetupStatus}/>}
      </Box>
    </>
  );
}
