import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import useFetcher, { endpoints } from 'src/utils/axios';
// routes
import { GlossaryList } from 'src/types/glossary';
// hooks
import { useDashboardContext } from 'src/context/hooks';
import {
  EngineDataWithLanguagePair,
  TranslateEngine,
  translationEngineOptions,
} from 'src/types/translateEngine';
import { IUser } from 'src/types/user';
import { Tab } from '@mui/material';
import { useBoolean } from '../../hooks/use-boolean';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { ConfirmDialog } from '../../components/custom-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../components/table';
import Label from '../../components/label';

// types
import { IOrgCredentialTableFilterValue, ITableFilters } from '../../types/orgCredential';
import TableToolbar from './table-toolbar';
import TableFiltersResult from './table-filters-result';
import TableRowMetadata from './table-row-metadata';
import { useFetchTranslateEngine } from './useFetchTranslateEngine';
import { TRANSLATION_ENGINE_STATUS_OPTIONS } from '../../_mock';

//
// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...TRANSLATION_ENGINE_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'glossaryList', label: 'Glossary' },
  { id: 'translationEngine', label: 'Translation Engine' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'lastModifiedBy', label: 'Last Modified By' },
  { id: 'createdDate', label: 'Created Date' },
  { id: 'lastModifiedDate', label: 'Last Modified Date' },
  { id: 'translationEngineStatus', label: 'Status' },
  { id: '', width: 88 },
];

const defaultFilters: ITableFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
};
interface ListViewProps {
  translateEngine: TranslateEngine[];
  glossaryList: GlossaryList[];
  setTranslateEngine: (
    translateEngineUpdater: (prevTranslateEngine: TranslateEngine[]) => TranslateEngine[]
  ) => void;
  setTranslationEngineMappedLanguageData: (
    translateEngineUpdater: (
      prevTranslateEngine: EngineDataWithLanguagePair[]
    ) => EngineDataWithLanguagePair[]
  ) => void;
}
// ----------------------------------------------------------------------

export default function ListView({
  translateEngine,
  glossaryList,
  setTranslateEngine,
  setTranslationEngineMappedLanguageData,
}: ListViewProps) {
  const [users, setUsers] = useState<IUser[]>([]);
  const { currentOrg } = useDashboardContext();
  const { fetchData, deleteData, postData } = useFetcher();
  const { fetchTranslateEngineData } = useFetchTranslateEngine();
  const table = useTable({ defaultOrderBy: 'orderNumber' });

  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const dataFiltered = applyFilter({
    inputData: translateEngine,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 40 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;

  const handleFilters = useCallback(
    (name: string, value: IOrgCredentialTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  const handleDeleteRow = useCallback(
    (id: string) => {
      deleteData([endpoints.translateEngine.delete, { params: { id } }]).then(() => {
        fetchTranslateEngineData()
          .then((response) => {
            setTranslateEngine(response);
          })
          .catch((error) => {
            console.error('Error fetching translation engines:', error);
          });
      });
    },
    [deleteData, setTranslateEngine, fetchTranslateEngineData]
  );

  const handleDeleteRows = () => {
    postData(endpoints.translateEngine.deleteMany, table.selected).then(() => {
      fetchTranslateEngineData()
        .then((response) => {
          setTranslateEngine(response);
          table.setSelected([]);
        })
        .catch((error) => {
          console.error('Error fetching translation engines:', error);
        });
    });
  };

  useEffect(() => {
    fetchData(endpoints.users.get).then((response) => setUsers(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  return (
    <>
      <Card
        sx={{
          m: 1,
          p: 6,
        }}
      >
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={tab.value === 'all' || tab.value === filters.status ? 'filled' : 'soft'}
                  color={
                    (tab.value === 'active' && 'success') ||
                    (tab.value === 'inactive' && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && translateEngine.length}
                  {tab.value === 'active' &&
                    translateEngine?.filter((data) => data.translationEngineStatus === true).length}
                  {tab.value === 'inactive' &&
                    translateEngine?.filter((data) => data.translationEngineStatus === false)
                      .length}
                </Label>
              }
            />
          ))}
        </Tabs>

        <TableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
        />

        {canReset && (
          <TableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered?.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={translateEngine.length}
            onSelectAllRows={(checked) => {
              const rowsToDeselect = translateEngine
                .filter((row) => row.isCustomTranslationEngine)
                .map((row) => row._id);

              table.onSelectAllRows(false, rowsToDeselect);
            }}
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={translateEngine.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) => {
                  const filteredRowIds = translateEngine
                    .filter((row) => row.isCustomTranslationEngine)
                    .map((row) => row._id);

                  table.onSelectAllRows(checked, filteredRowIds);
                }}
              />

              <TableBody>
                {dataInPage.map((row, index) => (
                  <TableRowMetadata
                    users={users}
                    key={row._id}
                    row={row}
                    onDeleteRow={(id: string) => handleDeleteRow(id)}
                    selected={table.selected.includes(row._id)}
                    onSelectRow={() => table.onSelectRow(row._id)}
                    onViewRow={() => null}
                    priorityLength={translateEngine?.length}
                    setTranslateEngine={setTranslateEngine}
                    glossaryList={glossaryList}
                    setTranslationEngineMappedLanguageData={setTranslationEngineMappedLanguageData}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, translateEngine.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered?.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: TranslateEngine[] | [];
  comparator: (a: any, b: any) => number;
  filters: ITableFilters;
  dateError: boolean;
}) {
  if (Array.isArray(inputData)) {
    const { status, name } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter(
        (engine) =>
          engine._id.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          engine.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }

    if (status === 'active') {
      inputData = inputData.filter((engine) => engine.translationEngineStatus === true);
    }

    if (status === 'inactive') {
      inputData = inputData.filter((engine) => engine.translationEngineStatus === false);
    }
  }

  return inputData;
}
