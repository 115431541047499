import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { useAuthContext } from 'src/auth/hooks';
import { useDashboardContext } from 'src/context/hooks';

const getResults = async (q: any) => {
  try {
    const querySnapshot = await getDocs(q);
    const results: any = [];
    querySnapshot.forEach((doc_: any) => {
      results.push({ id: doc_.id, ...doc_.data() });
    });
    return results;
  } catch (error) {
    console.error('Error getting documents: ', error);
    throw error;
  }
};

const usePermission = (permission: string) => {
  const { currentUser, db } = useAuthContext();
  const [allowedPermission, setAllowedPermission] = useState(null);
  const [isPermissionLoading, setPermissionLoading] = useState(true);
  const [currentRole, setCurrentRole] = useState('translator');
  const { currentOrg } = useDashboardContext();
  const getUserRole = async (userID: string) => {
    try {
      if (currentOrg) {
        const q = query(collection(db, 'org_to_user'), where('user_id', '==', userID));
        const userData = await getResults(q);
        const userRole = userData.find((user: any) => user.org_id === currentOrg?.org_id).role;
        setCurrentRole(userRole);
        return userRole;
      }
      return null;
    } catch (error) {
      console.error('Error getting documents: ', error);
      throw error;
    }
  };

  const getAssignedRoles = async (permission_: string) => {
    try {
      const q = query(
        collection(db, 'permission'),
        where('permission_api_name', '==', permission_)
      );
      const userPermission = await getResults(q);

      if (userPermission.length > 0) {
        return userPermission[0].assigned_roles;
      }
      return null;
    } catch (error) {
      console.error('Error getting documents: ', error);
      throw error;
    }
  };

  useEffect(() => {
    const checkPermission = async () => {
      if (currentUser) {
        const userPermission = await getAssignedRoles(permission);
        const userRole = await getUserRole(currentUser.uid);
        if (userPermission && userRole) {
          setAllowedPermission(userPermission.includes(userRole));
          setPermissionLoading(false);
        } else {
          setPermissionLoading(false);
        }
      }
    };
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, permission, currentOrg]);

  return { allowedPermission, isPermissionLoading, currentRole };
};

export default usePermission;
