import { flowAspect } from './flows';
import { ITreeView } from '../../types/translatable';

export const ITreeItemAllMetadata: ITreeView[] = [
  {
    id: 'quickActions',
    metadata_type_label: 'Action',
    metadata_type_api_name: 'quickActions',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      { id: 'quickActions.label', aspect: 'Field Label', selectable: false, selected: false },
      // {
      //   id: 'quickActions.successMessage',
      //   aspect: 'Informational Message',
      //   selectable: false,
      //   selected: false,
      // },
    ],
  },
  // {
  //   id: '??addressCoutry',
  //   metadata_type_label: 'Address Country',
  //   metadata_type_api_name: '??addressCoutry',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??addressCoutry.label',
  //       aspect: 'Address Country Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??addressState',
  //   metadata_type_label: 'Address State',
  //   metadata_type_api_name: '??addressState',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??addressState.label',
  //       aspect: 'Address State Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: 'caseValues',
  //   metadata_type_label: 'Object Name Case Value',
  //   metadata_type_api_name: 'caseValues',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: 'sharingReasons.value',
  //       aspect: 'Case Value',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  {
    id: 'sharingReasons',
    metadata_type_label: 'Apex Sharing Reason',
    metadata_type_api_name: 'sharingReasons',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'sharingReasons.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'customApplications',
    metadata_type_api_name: 'customApplications',
    metadata_type_label: 'App',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'customApplications.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'customApplications.description',
        aspect: 'Description',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'webLinks',
    metadata_type_api_name: 'webLinks',
    metadata_type_label: 'Button and Link Label',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'webLinks.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'fields',
    metadata_type_api_name: 'fields',
    metadata_type_label: 'Custom Field',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'fields.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'fields.relationshipLabel',
        aspect: 'Related List Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'fields.help',
        aspect: 'Help Text',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'customLabels',
    metadata_type_api_name: 'customLabels',
    metadata_type_label: 'Custom Label',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'customLabels.label',
        aspect: 'Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'reportTypes',
    metadata_type_api_name: 'reportTypes',
    metadata_type_label: 'Custom Report Type',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'reportTypes.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'reportTypes.description',
        aspect: 'Descriptrion',
        selectable: false,
        selected: false,
      },
      {
        id: 'reportTypes.columns',
        aspect: 'Column',
        selectable: false,
        selected: false,
      },
      {
        id: 'reportTypes.sections',
        aspect: 'Layout Section',
        selectable: false,
        selected: false,
      },
    ],
  },
  // {
  //   id: '??dataCategory',
  //   metadata_type_api_name: '??dataCategory',
  //   metadata_type_label: 'Data Category ',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??dataCategory.label',
  //       aspect: 'Data Category Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??dataCategoryGroup',
  //   metadata_type_api_name: '??dataCategoryGroup',
  //   metadata_type_label: 'Data Category Group ',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??dataCategoryGroup.label',
  //       aspect: 'Data Category Group Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??propertyType',
  //   metadata_type_api_name: '??propertyType',
  //   metadata_type_label: 'Experience Property Type',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??propertyType.label',
  //       aspect: 'Experience Property Type Field Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??propertyType.label',
  //       aspect: 'Experience Property Type Description',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??extension',
  //   metadata_type_api_name: '??extension',
  //   metadata_type_label: 'Extension',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??extension.label',
  //       aspect: 'Extension Help Text',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??extension.label',
  //       aspect: 'Extension Informational Message',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??feedFilter',
  //   metadata_type_api_name: '??feedFilter',
  //   metadata_type_label: 'Feed Filter',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??feedFilter.label',
  //       aspect: 'Feed Filter Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  {
    id: 'fieldSets',
    metadata_type_api_name: 'fieldSets',
    metadata_type_label: 'Field Set',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'fieldSets.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'flowDefinitions',
    metadata_type_api_name: 'flowDefinitions',
    metadata_type_label: 'Flow',
    selectable: false,
    selected: false,
    count: 0,
    aspect: flowAspect,
  },
  // {
  //   id: 'pipelineInspMetricConfigs',
  //   metadata_type_api_name: 'pipelineInspMetricConfigs',
  //   metadata_type_label: 'Forecasting Column Definition',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: 'pipelineInspMetricConfigs.label',
  //       aspect: 'Forecasting Column Definition Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },

  {
    id: 'globalPicklists',
    metadata_type_api_name: 'globalPicklists',
    metadata_type_label: 'Global Value Set',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'globalPicklists.label',
        aspect: 'Global Value Set Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'layouts',
    metadata_type_api_name: 'layouts',
    metadata_type_label: 'Layout Section',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'layouts.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'lookupFilter',
    metadata_type_api_name: 'lookupFilter',
    metadata_type_label: 'Lookup Filter',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'lookupFilter.errorMessage',
        aspect: 'Error Message',
        selectable: false,
        selected: false,
      },
      {
        id: 'lookupFilter.informationalMessage',
        aspect: 'Informational Message',
        selectable: false,
        selected: false,
      },
    ],
  }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_managedcontenttype.htm
  // {
  //   id: '??managedContentNodeTypes',
  //   metadata_type_api_name: 'managedContentNodeTypes',
  //   metadata_type_label: 'Managed Content Node Type',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??managedContentNodeTypes.fieldlabel',
  //       aspect: 'Managed Content Node Type Field Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??managedContentNodeTypes.helptext',
  //       aspect: 'Managed Content Node Type Help Text',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??managedContentNodeTypes.informationalmessage',
  //       aspect: 'Managed Content Node Type Informational Message',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_managedcontenttype.htm
  // {
  //   id: '??managedContentTypes',
  //   metadata_type_api_name: 'managedContentTypes',
  //   metadata_type_label: 'Managed Content Type',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??managedContentTypes.label',
  //       aspect: 'Managed Content Node Type Field Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??managedContentTypes.description',
  //       aspect: 'Managed Content Node Type Description',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_moderationrule.htm
  // {
  //   id: '??moderationrule',
  //   metadata_type_api_name: '??moderationrule',
  //   metadata_type_label: 'Moderation Rule',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??moderationrule.label',
  //       aspect: 'Moderation Rule Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??navigationMenuItem',
  //   metadata_type_api_name: '??navigationMenuItem',
  //   metadata_type_label: 'Navigation Menu Item',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??navigationMenuItem.label',
  //       aspect: 'Navigation Menu Item Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_pathassistant.htm
  // {
  //   id: '??pathstep',
  //   metadata_type_api_name: '??pathstep',
  //   metadata_type_label: 'Path Step Rich Text',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??pathstep.label',
  //       aspect: 'Navigation Menu Item Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  {
    id: 'picklistValues',
    metadata_type_api_name: 'picklistValues',
    metadata_type_label: 'Picklist Value',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'picklistValues.translation',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_translations.htm#PromptTranslation
  {
    id: 'prompts',
    metadata_type_api_name: 'prompts',
    metadata_type_label: 'Prompt',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'prompts.label',
        aspect: 'Prompt Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  {
    id: 'promptVersions',
    metadata_type_api_name: 'promptVersions',
    metadata_type_label: 'Prompt Version',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'promptVersions.label',
        aspect: 'Prompt Version Field Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.description',
        aspect: 'Prompt Version Description',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.title',
        aspect: 'Prompt Version Title',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.body',
        aspect: 'Prompt Version Body',
        selectable: false,
        selected: false,
      },
      {
        id: 'prompts.promptVersions.actionButtonLink',
        aspect: 'Prompt Version Action Button Link',
        selectable: false,
        selected: false,
      },
      {
        id: 'prompts.promptVersions.actionButtonLabel',
        aspect: 'Prompt Version Action Button Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.dismissButtonLabel',
        aspect: 'Prompt Version Dismiss Button Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.imageAltText',
        aspect: 'Prompt Version Image Alt Text',
        selectable: false,
        selected: false,
      },
      {
        id: 'promptVersions.imageLink',
        aspect: 'Prompt Version Image Link',
        selectable: false,
        selected: false,
      },
    ],
  }, //   https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_customobjecttranslation.htm#recordTypeTranslation
  {
    id: 'recordTypes',
    metadata_type_api_name: 'recordTypes',
    metadata_type_label: 'Record Type',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'recordTypes.label',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
      {
        id: 'recordTypes.description',
        aspect: 'Description',
        selectable: false,
        selected: false,
      },
    ],
  },
  // // ??
  // {
  //   id: '??repuationlevel',
  //   metadata_type_api_name: '??repuationlevel',
  //   metadata_type_label: 'Reputation Level',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??repuationlevel.label',
  //       aspect: 'Reputation Level Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // }, // https://developer.salesforce.com/docs/atlas.en-us.api_meta.meta/api_meta/meta_translations.htm#scontrolTranslation
  // {
  //   id: 'scontrols',
  //   metadata_type_api_name: 'scontrols',
  //   metadata_type_label: 'S-Control',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: 'scontrols.label',
  //       aspect: 'S-Control Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??solutionCategory',
  //   metadata_type_api_name: '??solutionCategory',
  //   metadata_type_label: 'Solution Category',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??solutionCategory.label',
  //       aspect: 'Solution Category Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??stamp',
  //   metadata_type_api_name: '??stamp',
  //   metadata_type_label: 'Stamp',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??stamp.label',
  //       aspect: 'Stamp Field Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??stamp.label',
  //       aspect: 'Stamp Description',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??standardfieldhelp',
  //   metadata_type_api_name: '??standardfieldhelp',
  //   metadata_type_label: 'Standard Field Help',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??standardfieldhelp.label',
  //       aspect: 'Standard Field Help',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: '??',
  //       aspect: 'Standard Field Help Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: '??.usercustombadge',
  //   metadata_type_api_name: '??.usercustombadge',
  //   metadata_type_label: 'User Custom Badge',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: '??usercustombadge.label',
  //       aspect: 'User Custom Badge Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  {
    id: 'validationRules',
    metadata_type_api_name: 'validationRules',
    metadata_type_label: 'Validation Error Message',
    selectable: false,
    selected: false,
    count: 0,
    aspect: [
      {
        id: 'validationRules.errorMessage',
        aspect: 'Field Label',
        selectable: false,
        selected: false,
      },
    ],
  },
  // {
  //   id: 'customPageWebLinks',
  //   metadata_type_api_name: 'customPageWebLinks',
  //   metadata_type_label: 'Web Tab',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: 'customPageWebLinks.label',
  //       aspect: 'Web Tab Label',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   id: 'workflowTasks',
  //   metadata_type_api_name: 'workflowTasks',
  //   metadata_type_label: 'Workflow Task',
  //   selectable: false,
  //   selected: false,
  //   count: 0,
  //   aspect: [
  //     {
  //       id: 'workflowTasks.subject',
  //       aspect: 'Workflow Task Subject',
  //       selectable: false,
  //       selected: false,
  //     },
  //     {
  //       id: 'workflowTasks.subject',
  //       aspect: 'Workflow Task Comment',
  //       selectable: false,
  //       selected: false,
  //     },
  //   ],
  // },
];
