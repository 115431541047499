import { useEffect, useState } from "react";
// types
import { IEnvironment } from "src/types/orgCredential";
// hooks
import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
//
import useFetcher, { endpoints } from "src/utils/axios";
// ----------------------------------------------------------------------

const stepStatusPagesMap: Record<string, number> = {
  started: 0,
  credentialSetup: 1,
  retrieveTranslation: 2,
  translationEngine: 3,
  finished: 4,
};

export default function useSetupWizzardHandler(): SetupWizzardHandler {
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [newWizzardStatus, setNewWizzardStatus] = useState('');
  const [activeStep, setActiveStep] = useState(-1);

  const { fetchData, updateData } = useFetcher();
  const { realTimeMessages } = useRealTimeContext();
  const { currentOrg } = useDashboardContext();

  useEffect(() => {
    if (realTimeMessages && currentOrg) {
      if (realTimeMessages[0]?.type === 'environment_refresh') {
        fetchEnv();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMessages]);
  
  const fetchEnv = () => {
    fetchData(endpoints.environment).then((env) => {
      setEnvironments(env);
    });
  };

  useEffect(() => {
    if (currentOrg) {
      setNewWizzardStatus(currentOrg.setupWizzardStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  const handleNext = () => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
    saveWizzardStatus(newStep);
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
    saveWizzardStatus(newStep);
  };

  const saveWizzardStatus = async (step: number) => {
    const currentStepStatus = Object.keys(stepStatusPagesMap).find((key) => stepStatusPagesMap[key] === step);
    if (currentStepStatus && currentOrg) {
      const updatedOrg = { ...currentOrg, setupWizzardStatus: currentStepStatus };
      setNewWizzardStatus(currentStepStatus);
      updateData(endpoints.org.put, updatedOrg);
    }
  };

  const handleReset = () => {
    setActiveStep(1);
    saveWizzardStatus(1);
  };

  const setFinishStatus = () => {
    if (currentOrg?.org_id) {
      const updatedOrg = { ...currentOrg, setupWizzardStatus: 'completed' }
      updateData(endpoints.org.put, updatedOrg);
    }
  };

  return {
    environments,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handleReset,
    setFinishStatus,
    newWizzardStatus,
  };
}

export type SetupWizzardHandler = {
  environments: IEnvironment[];
  activeStep: number;
  setActiveStep: (step: number) => void;
  handleNext: () => void;
  handleBack: () => void;
  handleReset: () => void;
  setFinishStatus: () => void;
  newWizzardStatus: string;
};