// auth
import { AuthGuard } from 'src/auth/guard';
// components
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import DocsLayout from 'src/layouts/docs/layout';
import { LoadingScreen } from 'src/components/loading-screen';
import { DashBoardProvider } from 'src/context/dashboard/provider';
import { RealTimeProvider } from 'src/context/realtimeUpdates/provider';

// ----------------------------------------------------------------------

const Overview = lazy(() => import('src/pages/docs/overview'));
// User
const UserRoles = lazy(() => import('src/pages/docs/user/roles'));
const Register = lazy(() => import('src/pages/docs/user/register'));
const InviteUser = lazy(() => import('src/pages/docs/user/invite'));
const PasswordReset = lazy(() => import('src/pages/docs/user/passwordReset'));
// Setup
const SetupApproval = lazy(() => import('src/pages/docs/setup/approval'));
const OrgCredentials = lazy(() => import('src/pages/docs/setup/orgCredentials'));
const TranslationEngine = lazy(() => import('src/pages/docs/setup/translationEngine'));
const Glossary = lazy(() => import('src/pages/docs/setup/glossary'));
const Notifications = lazy(() => import('src/pages/docs/setup/notifications'));
// Meta Translator
const RetrieveMetadata = lazy(() => import('src/pages/docs/translationWorkbench/retrieveMetadata'));
const FilterMetadata = lazy(() => import('src/pages/docs/translationWorkbench/filterMetadata'));
const TranslateMetadata = lazy(
  () => import('src/pages/docs/translationWorkbench/translateMetadata')
);
const DeployMetadata = lazy(() => import('src/pages/docs/translationWorkbench/deployMetadata'));
const Limit = lazy(() => import('src/pages/docs/translationWorkbench/limit'));
const TokenHistory = lazy(() => import('src/pages/docs/translationWorkbench/tokenHistory'));
// MTJob
const CreateUpdateJob = lazy(() => import('src/pages/docs/MTJob/createUpdateJob'));
const MTJobStatus = lazy(() => import('src/pages/docs/MTJob/status'));
const CompareOrgs = lazy(() => import('src/pages/docs/MTJob/compareOrgs'));
const OrgsDeployment = lazy(() => import('src/pages/docs/MTJob/orgsDeployment'));
// Salesforce
const RealTimeDataTranslation = lazy(
  () => import('src/pages/docs/salesforce/realTimeDataTranslation')
);
const LanguageDetection = lazy(() => import('src/pages/docs/salesforce/languageDetection'));
const FeedItemPostTranslation = lazy(
  () => import('src/pages/docs/salesforce/feedItemPostTranslation')
);
const AuthenticationSalesforce = lazy(
  () => import('src/pages/docs/salesforce/authenticationSalesforce')
);
const InstallPackage = lazy(() => import('src/pages/docs/salesforce/installPackage'));
const EditRecordPage = lazy(() => import('src/pages/docs/salesforce/editRecordPage'));
const EmailTranslation = lazy(() => import('src/pages/docs/salesforce/emailTranslation'));
const CaseTranslation = lazy(() => import('src/pages/docs/salesforce/caseTranslation'));
// OpenAPIs
const Token = lazy(() => import('src/pages/docs/openApis/token'));
// Extensions
const STCiCdComponents = lazy(() => import('src/pages/docs/extensions/STCiCdComponents'));

// ----------------------------------------------------------------------
const queryParameters = new URLSearchParams(window.location.search);

const inviteKey = queryParameters.get('inviteKey');

export const docsRoutes = [
  {
    path: 'docs',
    element: (
      <AuthGuard inviteKey={inviteKey}>
        <DashBoardProvider>
          <RealTimeProvider>
            <DocsLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DocsLayout>
          </RealTimeProvider>
        </DashBoardProvider>
      </AuthGuard>
    ),
    children: [
      { path: 'overview', element: <Overview /> },
      { path: 'user/roles', element: <UserRoles /> },
      { path: 'user/register', element: <Register /> },
      { path: 'user/invite', element: <InviteUser /> },
      { path: 'user/passwordReset', element: <PasswordReset /> },
      { path: 'setup/approval', element: <SetupApproval /> },
      { path: 'setup/orgCredentials', element: <OrgCredentials /> },
      { path: 'setup/translationEngine', element: <TranslationEngine /> },
      { path: 'setup/glossary', element: <Glossary /> },
      { path: 'setup/notifications', element: <Notifications /> },
      { path: 'translationWorkbench/retrieveMetadata', element: <RetrieveMetadata /> },
      { path: 'translationWorkbench/filterMetadata', element: <FilterMetadata /> },
      { path: 'translationWorkbench/deployMetadata', element: <DeployMetadata /> },
      { path: 'translationWorkbench/translateMetadata', element: <TranslateMetadata /> },
      { path: 'translationWorkbench/limit', element: <Limit /> },
      { path: 'translationWorkbench/tokenHistory', element: <TokenHistory /> },
      { path: 'MTJob/createUpdateJob', element: <CreateUpdateJob /> },
      { path: 'MTJob/status', element: <MTJobStatus /> },
      { path: 'MTJob/compareOrgs', element: <CompareOrgs /> },
      { path: 'MTJob/orgsDeployment', element: <OrgsDeployment /> },
      { path: 'salesforce/languageDetection', element: <LanguageDetection /> },
      { path: 'salesforce/realTimeDataTranslation', element: <RealTimeDataTranslation /> },
      { path: 'salesforce/authenticationSalesforce', element: <AuthenticationSalesforce /> },
      { path: 'salesforce/caseTranslation', element: <CaseTranslation /> },
      { path: 'salesforce/feedItemPostTranslation', element: <FeedItemPostTranslation /> },
      { path: 'salesforce/installPackage', element: <InstallPackage /> },
      { path: 'salesforce/editRecordPage', element: <EditRecordPage /> },
      { path: 'salesforce/emailTranslation', element: <EmailTranslation /> },
      { path: 'openAPIs/token', element: <Token /> },
      { path: 'extensions/stCiCdComponent', element: <STCiCdComponents /> },
    ],
  },
];
