import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
// types
import { IOrgCredentialRow } from 'src/types/orgCredential';

// components
import { useDashboardContext } from 'src/context/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  credential?: IOrgCredentialRow;
};

export default function UserQuickEditForm({ credential, open, onClose }: Props) {
  const { updateData } = useFetcher();
  const { currentOrg } = useDashboardContext();
  const NewUserSchema = Yup.object().shape({
    org_crediantial_name: Yup.string().required('Org Name is required'),
    credentials_id: Yup.string(),
    instance_url: Yup.string(),
    status: Yup.string(),
    login_url: Yup.string(),
    type: Yup.string(),
    lastModifiedBy: Yup.string(),
    lastModifiedDate: Yup.string(),

  });

  const defaultValues = useMemo(
    () => ({
      credentials_id: credential?.credentials_id || '',
      org_crediantial_name: credential?.org_crediantial_name || '',
      instance_url: credential?.instance_url || '',
      status: credential?.status || '',
      login_url: credential?.login_url || '',
      type: credential?.type|| '',
      lastModifiedBy: credential?.lastModifiedBy || '',
      lastModifiedDate: credential?.lastModifiedDate || '',
    }),
    [credential]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = () => {
    try {
      const credentialBody = {...credential, org_crediantial_name: values.org_crediantial_name}
      updateData(endpoints.credential.update, [credentialBody]).then(() => 
        onClose()
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Credential Update</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 2 }}
          >
            <RHFTextField name="credentials_id" label="Org Id" disabled />
            <RHFTextField name="org_crediantial_name" label="Org Name *" />
            <RHFTextField name="instance_url" label="Instance URL" disabled />
            <RHFTextField name="status" label="Status" disabled />
            <RHFTextField name="login_url" label="Login URL" disabled />
            <RHFTextField name="type" label="Type" disabled />
            <RHFTextField name="lastModifiedBy" label="Last Modified By" disabled />
            <RHFTextField name="lastModifiedDate" label="Last Modified Date" disabled />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton variant="contained" onClick={onSubmit}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
