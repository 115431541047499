import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
// layouts
import MainLayout from 'src/layouts/main';

// ----------------------------------------------------------------------
const Impressum = lazy(() => import('src/pages/legal/impressum'));
const Disclaimer = lazy(() => import('src/pages/legal/disclaimer'));
const EeaStandardContractualClauses = lazy(() => import('src/pages/legal/eeaStandardContractualClauses'));
const CookiePolicy = lazy(() => import('src/pages/legal/cookiePolicy'));
const PrivacyPolicy = lazy(() => import('src/pages/legal/privacyPolicy'));
const DataProcessingAgreement = lazy(() => import('src/pages/legal/dataProcessingAgreement'));
const TermsAndConditions = lazy(() => import('src/pages/legal/termsAndConditions'));

// ----------------------------------------------------------------------

export const legalRoutes = [
  {
    path: 'legal',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'impressum', element: <Impressum /> },
      { path: 'disclaimer', element: <Disclaimer /> },
      { path: 'eea-standard-contractual-clauses', element: <EeaStandardContractualClauses /> },
      { path: 'cookie-policy', element: <CookiePolicy /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'data-processing-agreement', element: <DataProcessingAgreement /> },
      { path: 'terms-and-conditions', element: <TermsAndConditions /> },
    ],
  },
];
